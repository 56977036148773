<script setup lang="ts">
</script>

<template>
  <div data-layout="site/landing">
    <div class="bg-forgd-bgd-400 min-h-screen font-normal text-forgd-primary-900 p-2 lg:p-6 pattern">
      <div>
        <slot />
      </div>
    </div>
    <SiteFooter class="bg-forgd-bgd-400 hidden md:block" />

    <div class="bg-forgd-bgd-400 md:hidden block">
      <div class="pb-8 pt-4 lg:flex lg:items-center lg:justify-between max-w-7xl mx-auto px-6">
        <div class="flex items-center justify-center space-x-9 text-black lg:mb-0">
          <NuxtLink
            to="https://forgd.gitbook.io/explore/"
            target="'_blank'"
            class="text-forgd-primary-900 text-sm font-normal hover:text-forgd-primary-300 hover:underline"
          >
            <div class="flex space-x-1">
              <span>Forgd on GitBook</span>
              <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.4688 2.28125L16.625 2.28125C16.7825 2.28125 16.9335 2.34381 17.0448 2.45516C17.1562 2.56651 17.2188 2.71753 17.2188 2.875V7.03125C17.2188 7.35917 16.9529 7.625 16.625 7.625C16.2971 7.625 16.0312 7.35917 16.0312 7.03125V4.30844L6.35734 13.9823C6.12547 14.2142 5.74953 14.2142 5.51766 13.9823C5.28578 13.7505 5.28578 13.3745 5.51766 13.1427L15.1916 3.46875L12.4688 3.46875C12.1408 3.46875 11.875 3.20292 11.875 2.875C11.875 2.54708 12.1408 2.28125 12.4688 2.28125ZM4.15625 5.84375C3.50041 5.84375 2.96875 6.37541 2.96875 7.03125V15.3438C2.96875 15.9996 3.50041 16.5312 4.15625 16.5312H12.4688C13.1246 16.5312 13.6562 15.9996 13.6562 15.3438V8.8125C13.6562 8.48458 13.9221 8.21875 14.25 8.21875C14.5779 8.21875 14.8438 8.48458 14.8438 8.8125V15.3438C14.8438 16.6554 13.7804 17.7188 12.4688 17.7188H4.15625C2.84457 17.7188 1.78125 16.6554 1.78125 15.3438V7.03125C1.78125 5.71957 2.84457 4.65625 4.15625 4.65625H10.6875C11.0154 4.65625 11.2812 4.92208 11.2812 5.25C11.2812 5.57792 11.0154 5.84375 10.6875 5.84375H4.15625Z" fill="#001221" />
              </svg>
            </div>
          </NuxtLink>
        </div>

        <div class="flex justify-center space-x-9 my-6">
          <NuxtLink href="https://linkedin.com/company/forgd-by-solana/" target="_blank">
            <img src="/images/linkedin.svg">
          </NuxtLink>

          <NuxtLink href="https://x.com/forgd_" target="_blank">
            <img src="/images/twitter.svg">
          </NuxtLink>
        </div>

        <div class="text-xs font-normal text-center">
          &copy; {{ new Date().getFullYear() }} Forgd. All rights reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .pattern {
    background-image: none;
  }

  @media (min-width: 768px) {
    .pattern {
      background-image: url('/images/homepage/pattern.svg');
      background-repeat: repeat;
      background-attachment: fixed;
    }
  }
</style>
